@import '~Assets/Styles/Pepxcite/Variables/Breakpoints';

@mixin large {
  @media only screen and (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin medium {
  @media only screen and (min-width: $screen-md-min) {
    @content;
  }
}

@mixin small {
  @media only screen and (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin break($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}
