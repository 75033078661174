@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';

.section + .section {
  margin-top: 24px;
}

.status {
  border: 4px solid $ibe-color-gray-300;
  padding: 12px 18px;
  border-radius: 4px;

  .colored {
    color: $ibe-color-gray-300;
    font-weight: bold;
  }

  div {
    span {
      white-space: nowrap;
    }
  }
  div + div {
    margin-top: 6px;
  }
}

.status--green {
  border-color: green;
  .colored {
    color: green;
  }
}

.status--orange {
  border-color: darkorange;
  .colored {
    color: darkorange;
  }
}
