@import '../../../Assets/Styles/Pepxcite/Variables/Colors';
@import '../../../Assets/Styles/Pepxcite/Variables/Breakpoints';
@import '../../../Assets/Styles/Pepxcite/Mixins/MediaQuery';

.crumbs {
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: stretch;
  margin: 0 0 24px 0;
  padding: 0;
}

.crumb {
  display: block;
  flex-grow: 1;
  margin: 0;
  padding: 0;

  /* prevent flicker */
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.crumb {
  & + & {
    margin-left: 18px;
  }

  .link {
    display: block;
    background: $ibe-color-gray-200;
    text-align: center;
    padding: 6px 12px 6px 36px;
    position: relative;
    text-decoration: none;
    color: inherit;

    &::after {
      content: '';
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid $ibe-color-gray-200;
      position: absolute;
      right: -18px;
      top: 0;
      z-index: 1;
    }

    &::before {
      content: '';
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
      border-left: 18px solid $ibe-color-white;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &:first-child .link {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding-left: 12px;
    &::before {
      display: none;
    }
  }

  &:last-child .link {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-right: 12px;
    &::after {
      display: none;
    }
  }

  &--isEnabled {
    cursor: pointer;
    .link {
      background: $primary-dark;
      color: $ibe-color-white;

      &::after {
        border-left-color: $primary-dark;
      }

      &:hover {
        background: $primary-dark;
        color: $ibe-color-white;

        &::after {
          border-left-color: $primary-dark;
        }
      }
    }
  }

  &--isDisabled {
    cursor: default;
  }

  &--isActive {
    cursor: default;

    .link {
      background: $primary;
      color: $ibe-color-white;

      &::after {
        border-left-color: $primary;
      }

      .number {
        @include break($ibe-sm-breadcrumbs) {
          display: none;
        }
      }

      .name {
        @include break($ibe-sm-breadcrumbs) {
          display: inline;
        }
      }
    }
  }
}

.name {
  display: none;

  @include break($ibe-md-breadcrumbs) {
    display: inline;
  }
}

.number {
  display: inline;

  @include break($ibe-md-breadcrumbs) {
    display: none;
  }
}

.separator {
  display: none;
}
