@import '../../Assets/Styles/Pepxpress/Variables/Colors';

.image {
  background-color: $ibe-color-gray-300;
  div {
    height: 240px;
    background-size: cover;
    background-position: center center;
  }
}
