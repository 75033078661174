@import '~Assets/Styles/Pepxcite/Variables/Colors';
@import '~Assets/Styles/Pepxcite/Mixins/MediaQuery';

$ibe-color-offer: $primary-light;

.offerbox {
  border: 0;
  height: calc(100% - 1rem);

  &:hover {
    .cardFooter {
      background-color: $primary !important;
      a {
        color: $white !important;
        transition: none;
        text-decoration: none;
        &:after {
          content: '>';
          color: $white !important;
          font-size: 16px;
          font-weight: bolder;
          margin-left: 2px;
        }
      }
    }
  }
}

.headline {
  color: $primary;
  margin-bottom: 1.5rem;
  text-align: center;
}

.subHeadline {
  font-size: 18px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  color: $white;
  background-color: $ibe-color-offer !important;
}
.cardBody {
  background-color: #f7f7f7;
}
.cardTitle {
  font-size: 22px;
}
.cardSubTitle {
  font-size: 16px;
  font-weight: bold;
}
.cardDate {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.cardText {
  font-size: 14px;
  ul {
    margin: 0;
    padding: 0 15px;
    list-style: none;

    li {
      margin: 5px 0;
      padding: 0;

      &:before {
        content: '\2022';
        color: $ibe-color-offer;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
}

.cardFooter {
  background-color: #e6e6e6;
  border-radius: 0 0 15px 0 !important;
  padding: 0 !important;
  a {
    color: $primary !important;
    font-size: 16px;
    padding: 10px 20px;
    display: block;
    font-weight: bold;

    &:after {
      content: '>';
      color: $primary;
      font-size: 16px;
      font-weight: bolder;
      margin-left: 2px;
    }
  }
  &:hover {
    background-color: $primary;
    a {
      color: $white !important;
      transition: none;
      text-decoration: none;
      &:after {
        content: '>';
        color: $white !important;
        font-size: 16px;
        font-weight: bolder;
        margin-left: 2px;
      }
    }
  }
}

.price {
  font-weight: bold;
  color: white !important;
}

.infoUrl {
  color: $white;
}
.image {
  width: 100%;
}

.teaser {
  a:hover {
    color: #212529;
    text-decoration: none;
  }

  &__text {
    font-size: 21px;
    color: #212529;
    background-color: #f7f7f4;
    padding: 12px 28px;
    border-bottom-right-radius: 20px;
  }

  &:hover {
    .teaser__text {
      background-color: #af0055;
      color: #fff;
    }
  }
}

.info {
  img {
    height: 170px;

    @include large {
      height: inherit;
      max-height: 140px;
    }
  }

  h3 {
    margin: 20px 0 3px;
    font-size: 24px;
    color: #af0055;
    font-weight: 600;
  }

  .text {
    max-width: 250px;
    text-align: center;
  }

  &__container {
    padding: 1.5rem 1.5rem 0.5rem;
    background-color: #f7f7f7;
    margin-bottom: 1.5rem;
  }
}

.teaserLink {
  &:hover {
    text-decoration: none;
  }
}

.banner {
  margin: 1.5rem 0;
  width: 100%;
  position: relative;
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  padding: 1rem 1.5rem;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @include medium {
    padding: 2vmax 2rem;
    min-height: 190px;
  }

  ul {
    padding-left: 22px;
    margin-top: 12px;

    @include medium {
      margin-top: 15px;
      padding-left: 2.5vmin;
      font-size: calc(min(2vmin, 1.2rem));
    }
  }

  &Inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 10;

    @include medium {
      flex-direction: row;
    }
  }

  &Date {
    margin-top: 30px;

    @include medium {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
      font-size: calc(min(2vmin, 1.2rem));
    }

    > div:last-child {
      line-height: 1;
      margin-top: 5px;
    }
  }

  &Headline {
    font-size: 1.25rem;
    font-weight: 600;

    @include medium {
      font-size: calc(min(2.8vmin, 2.2rem));
    }
  }

  &Gallery {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    max-height: 100%;
    align-items: center;
    overflow: hidden;
    height: 100%;

    div {
      height: 100%;
    }

    > div {
      flex: 1;
    }

    &Renderer {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
    }
  }
}
