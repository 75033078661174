@import '~Assets/Styles/Pepxpress/Variables/Colors';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: baseline;
  cursor: pointer;
  margin: 0;
}

.info {
  color: $ibe-color-pink !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
