@import "Colors";
@import "Borders";
//bootstrap
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-font-family: null !default;

$input-btn-focus-width: 0.2rem !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;

$input-btn-border-width: $border-width !default;

$input-border-color: $gray-400 !default;
$input-placeholder-color: $gray-600 !default;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-x !default;

$line-height-base: 1.5 !default;
$input-btn-line-height: $line-height-base !default;
$input-line-height: $input-btn-line-height !default;

$input-height-border: $input-btn-border-width * 2 !default;

$input-height: calc(
  #{$input-line-height * 1em} + #{$input-btn-padding-y * 2} + #{$input-height-border}
);

//ibe
$input-btn-focus-color: rgba($ibe-color-gray-600, 0.25);
$input-focus-border-color: $ibe-color-gray-600 !default;
