@import '../../Assets/Styles/Pepxcite/Variables/Colors';

.items {
}

.item {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  cursor: default;
}

.item + .item {
  margin-top: 6px;
  border-top: 1px solid $ibe-color-gray-300;
  padding-top: 6px;
}

.itemGroup + .itemGroup {
  margin-top: 12px;
  border-top: 1px solid $primary;
  padding-top: 12px;
}

.itemBold {
  font-weight: bold;
}

.name {
  flex-shrink: 1;
}

.price {
  width: 96px;
  flex-shrink: 0;
  text-align: right;
  position: relative;
}

.additionalRow {
  flex-shrink: 0;
  width: 100%;
}

.details {
  display: table;
  font-size: 13px;
  line-height: 18px;
}

.detail {
  display: table-row;

  & > strong,
  & > span {
    display: table-cell;
  }

  & > * + * {
    padding-left: 6px;
  }
}

.infoIcon {
  // color    : $ibe-color-gray-400;
  color: $primary;
  position: relative;
  right: 3px;
  top: -2px;
}
