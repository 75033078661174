// bootstrap default vars

$ibe-color-white: #fff;
$ibe-color-gray-100: #f8f9fa;
$ibe-color-gray-200: #e9ecef;
$ibe-color-gray-300: #dee2e6;
$ibe-color-gray-400: #ced4da;
$ibe-color-gray-500: #adb5bd;
$ibe-color-gray-600: #868e96;
$ibe-color-gray-700: #495057;
$ibe-color-gray-800: #343a40;
$ibe-color-gray-900: #212529;
$ibe-color-black: #000;

$ibe-color-blue: rgb(21, 73, 152);
$ibe-color-indigo: #6610f2;
$ibe-color-purple: rgb(223, 165, 191);
$ibe-color-pink: rgb(175, 0, 85);
$ibe-color-red: #a94442; // match https://www.pepxpress.com
$ibe-color-orange: rgb(235, 128, 7);
$ibe-color-yellow: rgb(255, 208, 0);
$ibe-color-green: #28a745;
$ibe-color-teal: #20c997;
$ibe-color-green-light: #D4EDDA;
$ibe-color-green-dark: #85AF88;
$ibe-color-cyan: rgb(4, 174, 222);
$ibe-color-sand: #FCF8E3;
$ibe-color-sand-dark: #FDCB67;

// custom vars

$ibe-color-purple-darker: rgb(201, 96, 141);
$ibe-color-grey-dark: rgb(60, 59, 58);
$ibe-color-grey-light: rgb(135, 135, 135);

$white: $ibe-color-white;
$gray-100: $ibe-color-gray-100;
$gray-200: $ibe-color-gray-200;
$gray-300: $ibe-color-gray-300;
$gray-400: $ibe-color-gray-400;
$gray-500: $ibe-color-gray-500;
$gray-600: $ibe-color-gray-600;
$gray-700: $ibe-color-gray-700;
$gray-800: $ibe-color-gray-800;
$gray-900: $ibe-color-gray-900;
$black: $ibe-color-black;

$blue: $ibe-color-blue;
$indigo: $ibe-color-indigo;
$purple: $ibe-color-purple;
$pink: $ibe-color-pink;
$red: $ibe-color-red;
$orange: $ibe-color-orange;
$yellow: $ibe-color-yellow;
$green: $ibe-color-green;
$teal: $ibe-color-teal;
$green-light: $ibe-color-green-light;
$green-dark: $ibe-color-green-dark;
$sand: $ibe-color-sand;
$sand-dark: $ibe-color-sand-dark;
$cyan: $ibe-color-cyan;

$primary: $pink;

$secondary: $gray-600 !default;
