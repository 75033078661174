// bootstrap

$ibe-font-family-sans-serif: "Droid Sans", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";
$ibe-font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace;
$ibe-font-family-base: $ibe-font-family-sans-serif;

$headings-font-family: "Open Sans Condensed", #{$ibe-font-family-sans-serif};
$headings-font-weight: 700;

$font-weight-normal: 400 !default;
$font-weight-base: $font-weight-normal !default;
$input-font-weight: $font-weight-base !default;

// custom
$font-family-sans-serif: $ibe-font-family-sans-serif;
$font-family-monospace: $ibe-font-family-monospace;
$font-family-base: $ibe-font-family-sans-serif;
