@import '../../Assets/Styles/Pepxpress/Mixins/MediaQuery';

.person {
  margin-top: 24px;

  @include medium() {
    display: flex;

    & > * + * {
      margin-top: 0;
      margin-left: 24px;
    }
  }
}
