@import '../../Assets/Styles/Pepxpress/Variables/Colors';

.items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.item {
  position: relative;
  margin: 0;
  padding: 0 0 0 18px;

  svg {
    font-size: 17px;
    position: absolute;
    left: -2px;
    top: 3px;
    fill: $ibe-color-pink;
  }
}

.item + .item {
  margin-top: 6px;
}
