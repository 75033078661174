@import '~Assets/Styles/Pepxpress/Variables/Colors';

.container {
    display : inline-flex;
}

.title {
    
}

.sepaDebit {
    flex-flow: column;
    padding: 1rem 0;
    input {
        margin-bottom: 5px;
    }
}

.containerImageToggle {
    display        : flex;
    flex-direction : row;
    flex-wrap      : nowrap;
}

.toggleContainer {
    margin-left : .5rem;
}

.icon {
    margin-left : 6px;

    img {
        display    : block;
        width      : auto; /* img tag might override this */
        height     : auto; /* img tag might override this */
        max-width  : 120px;
        max-height : 24px;
    }
}

.optionInfoLink {

    span {
        color        : #C9608D;
        font-size    : 11px;
        text-align   : right;
        display      : block;
        line-height  : 12px;
        margin-right : 6px;
    }

    svg {
        color: $ibe-color-pink;

        &:hover {
            color: #630030;
        }
    }

    &:hover {
        text-decoration : none;

        span {
            color : #630030;
        }

        svg {
            fill : #630030;
        }
    }
}
