@import '~Assets/Styles/Pepxpress/Variables/Colors';

.content {
  margin-left: -30px;

  &__header {
    display: flex;
    justify-content: space-between;

    &__icon {
      color: $primary;
    }
  }
}

.descriptions {
  padding: 2px 12px 12px 1px;

  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li:before {
    color: $ibe-color-purple-darker;
    content: '» ';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -1px;
  }

  * + * {
    margin-top: 12px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: baseline;
  cursor: pointer;
  margin: 0;
}

.info {
  color: $ibe-color-pink !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0 1rem;
  padding: 0;
}
