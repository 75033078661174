@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Variables/Breakpoints';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';

.container {
}

.noShadow {
  box-shadow: none !important;
}

.small {
  margin-top: 12px;
  font-size: 12px;
  line-height: 18px;
}
.info {
  color: $ibe-color-pink !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.errorContainer {
  margin-top: 1rem;
  width: 100%;
}

.headlineContainer {
  display: flex;
  flex-direction: row;
}

.headlineText {
  margin-top: 35px;
}

.headlineImage {
  margin: 35px;
  width: 70px;
}

.trains {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.train {
  position: relative;
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  margin: 6px;
  padding: 4px;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  svg {
    fill: $ibe-color-gray-400;
  }

  &--highlight {
    border-color: $ibe-color-gray-500;

    svg {
      fill: $ibe-color-gray-500;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $ibe-color-pink;
  }
}

.trainDescription {
  padding: 12px;
  padding-bottom: 0;
}

.light {
  color: $ibe-color-gray-600;
}

.trainDescriptionHtml {
  padding: 2px 12px 12px 1px;
  margin-bottom: 10px;

  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  * + * {
    margin-top: 12px;
  }
}

.bulletHeadline {
  color: $ibe-color-pink;
  font-weight: 700;
}

.bulletHeadlineMargin {
  color: $ibe-color-pink;
  font-weight: 700;
  margin-top: 20px;
}

.bulletPoint {
  position: relative;
  padding-left: 16px;
}

.bulletPoint:before {
  color: $ibe-color-pink;
  content: '» ';
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  left: 0;
  top: -1px;
}

.seperator {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid $ibe-color-pink;
}

.topNav {
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (min-width: $screen-md-min) {
  .noPadding {
    padding-left: 0px;
  }
}
