@import '../../../Assets/Styles/Pepxpress/Variables/Colors';
@import '../../../Assets/Styles/Pepxpress/Variables/Breakpoints';
@import '../../../Assets/Styles/Pepxpress/Mixins/MediaQuery';

.container {
  margin-top: 12px;
  border-top: 1px solid $ibe-color-pink;
  padding-top: 12px;

  & > * + * {
    margin-top: 12px;
  }
}

.info {
  color: $ibe-color-pink;
}

.languageSelection {
  & > * + * {
    margin-left: 12px;
  }
}
