@import '../../Assets/Styles/Pepxpress/Mixins/MediaQuery';

.layout {
  display: block;
  overflow: hidden;
}

.top {
  margin-bottom: 24px;
}

.leftRight {
}

.left {
}

.right {
  margin-top: 24px;
}

.bottom {
  margin-top: 24px;
}

.layout--2col {
  .leftRight {
    @include medium() {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .left {
    @include medium() {
      width: 58%;
      padding-right: 12px;
    }
    @include large() {
      width: 65%;
    }
  }

  .right {
    @include medium() {
      margin-top: 0;
      width: 42%;
      padding-left: 12px;
    }
    @include large() {
      width: 35%;
    }
  }
}
