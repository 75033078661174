.nav {
    display         : flex;
    justify-content : space-between;

    button {
        display     : flex;
        align-items : center;
        justify-content: center;

        & > * + * {
            margin-left : 6px;
        }
    }
}