@import '../../Assets/Styles/Pepxpress/Variables/Variables';

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

.input {
  font-family: $font-family-base;
}

.container {
  position: relative;
}

.containerOpen {
}

.times {
  position: absolute;
  top: -4px;
  right: 16px;
  padding: 0;
  padding-right: 0.125rem;
  cursor: pointer;

  &:hover {
    border: $input-btn-border-width solid rgba($gray-700, 0.25);
    border-radius: $border-radius;
  }
}

.input {
  width: 100%;
  padding: $input-btn-padding-y $input-btn-padding-x;
  line-height: $input-line-height;
  height: $input-height;
  font-weight: $input-font-weight;
  color: $gray-700;
  background-color: $white;
  transition: $input-transition;
  border: $input-btn-border-width solid $input-border-color;
  border-radius: $border-radius;
}

.inputFocused {
  border: $input-btn-border-width solid rgba($gray-700, 0.25);
  color: $gray-700;
  background-color: $white;
  border-color: $input-focus-border-color;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0.2rem rgba($gray-700, 0.25);
  border-radius: $border-radius;
}

.inputOpen {
  // border: $input-btn-border-width solid rgba($gray-700, 0.25);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 !important;
}

.suggestionsContainer {
  display: none;
}

.suggestionsContainerOpen {
  min-width: 100%;
  display: block;
  position: absolute;
  top: 37px;
  border: $input-btn-border-width solid $input-border-color;
  background-color: $white;
  font-weight: $input-font-weight;
  z-index: 1202;
}

.suggestionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: block;
}

.suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.suggestionHighlighted {
  background-color: $gray-100;
}
