.row {
    display         : flex;
    justify-content : stretch;
    flex-wrap       : nowrap;
    align-items     : flex-start;
}

.label {
    width       : 20%;
    max-width   : 132px;
    flex-shrink : 0;
    padding-top : 7px; // input padding-top + border
    //white-space : nowrap;
}

.content {
    width           : 100%;
    flex-shrink     : 1;
    //display         : flex;
    //justify-content : space-between;
    //
    //& > * {
    //    margin : 6px;
    //    width  : 100%;
    //}
}