@import '../../../Assets/Styles/Pepxcite/Variables/Colors';
@import '../../../Assets/Styles/Pepxcite/Mixins/Radio';

.container {
}

.note {
  margin-top: 24px;
}

.group {
}

.group + .group {
  margin-top: 24px;
}

.service {
  padding: 12px;
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  display: flex;
  cursor: pointer;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $primary;
  }
}

.customRadio {
  $paddingLeft: 12px;
  @include checkboxAndRadio($paddingLeft);

  &--flex-pep > input[type='checkbox'] + label {
    &:before {
      bottom: 24px;
      margin-bottom: -10px;
      top: unset;
    }

    &:after {
      bottom: 24px;
      margin-top: unset;
      margin-bottom: -4px;
      top: unset;
    }
  }
}

.customRadio + .customRadio {
  margin-top: 12px;
}

.small {
  margin-top: 12px;
  font-size: 12px;
  line-height: 18px;
}
