@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';

.container {
}

.insurances {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.insurance {
  position: relative;
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  margin: 6px;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  svg {
    fill: $ibe-color-gray-400;
  }

  &--highlight {
    border-color: $ibe-color-gray-500;

    svg {
      fill: $ibe-color-gray-500;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $ibe-color-pink;
  }
}

.insuranceDescription {
  padding: 12px;
  padding-bottom: 0;
}

.insuranceInfo {
  position: absolute;
  right: 12px;
  top: 11px;

  svg {
    fill: $ibe-color-pink;
  }

  &:hover {
    svg {
      fill: #630030;
    }
  }
}

.insuranceLabel {
  padding: 12px;
  display: flex;
  margin: 0;
  padding: 12px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: baseline;
    margin: 0;
    cursor: pointer;
  }
}

.insuranceInput {
  width: 100%;
  $paddingLeft: 12px;
  @include checkboxAndRadio($paddingLeft);
}

.light {
  color: $ibe-color-gray-600;
}

.insuranceDescriptionItem {
  position: relative;
  padding-left: 30px;

  svg {
    position: absolute;
    left: 2px;
    top: 4px;
  }
}

.insuranceDescriptionItem + .insuranceDescriptionItem {
  margin-top: 3px;
}

.insuranceDescriptionHighlight {
  text-transform: uppercase;
  background-color: $ibe-color-pink;
  color: white;
  padding: 6px 12px;
  margin: 12px -12px 0;
}

.insuranceDescriptionHtml {
  padding: 2px 12px 12px 1px;

  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li:before {
    color: $ibe-color-purple-darker;
    content: '» ';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -1px;
  }

  * + * {
    margin-top: 12px;
  }
}

.insuranceDescriptionStatic {
  padding-top: 12px;
}

.insurance--sub {
  cursor: default;

  .link {
    cursor: pointer;
  }

  .insuranceLabel {
    padding-left: 12px;
  }

  .insuranceDescriptionHtml {
    cursor: default;
    padding: 12px 12px 0 12px;
  }

  .btnBox {
    text-align: right;
    padding: 12px;
  }
}
.headlineContainer {
  display: flex;
  flex-direction: row;
}

.headlineText {
  margin-top: 35px;
}

.headlineImage {
  margin: 35px;
  width: 70px;
}

.subhead {
  display: block;
  font-size: 75%;
}
