@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Variables/Breakpoints';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';

.container {
}

.noShadow {
  box-shadow: none !important;
}

.small {
  margin-top: 12px;
  font-size: 12px;
  line-height: 18px;
}
.info {
  color: $ibe-color-pink !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.errorContainer {
  margin-top: 1rem;
  width: 100%;
}

.headlineContainer {
  display: flex;
  flex-direction: row;
}

.headlineText {
  margin-top: 35px;
}

.headlineImage {
  margin: 35px;
  width: 70px;
}

.topNav {
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 415px) {
    display: none;
  }
}

:global(.topNav--mobile) {
  display: flex;
  flex-basis: 25%;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 415px) {
    display: none;
  }
}

:global(.addBtn) {
  margin-bottom: 10px;
  button {
    border: none;
  }
}

:global(.nextBtn) {
  margin-bottom: 10px;
  button {
    background-color: $ibe-color-purple;
    color: $ibe-color-gray-900;
    border: none;
  }
}

.trains {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  flex-wrap: wrap;
  margin: 0 -6px;
}

.train {
  position: relative;
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;
  flex-grow: 1;
  margin: 6px;
  padding: 4px;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  svg {
    fill: $ibe-color-gray-400;
  }

  &--highlight {
    border-color: $ibe-color-gray-500;

    svg {
      fill: $ibe-color-gray-500;
    }
  }

  &--fullWidth {
    width: 100%;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $ibe-color-pink;
  }
}

.trainDescription {
  padding: 12px;
  padding-bottom: 0;
}

.trainInfo {
  position: absolute;
  right: 12px;
  top: 11px;

  svg {
    fill: $ibe-color-pink;
  }

  &:hover {
    svg {
      fill: #630030;
    }
  }
}

.trainLabel {
  padding: 12px;
  display: flex;
  margin: 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: baseline;
    margin: 0;
    cursor: pointer;
  }
}

.trainInput {
  width: 100%;
  border: 0;
  // margin: 3px 10px;
  $paddingLeft: 12px;

  &--isSelected,
  &--isSelected:hover {
    border: 1px solid $ibe-color-pink;
    border-radius: 4px;
  }
  @include checkboxAndRadio($paddingLeft);
}

.light {
  color: $ibe-color-gray-600;
}

.trainDescriptionHtml {
  padding: 2px 12px 12px 1px;

  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li:before {
    color: $ibe-color-purple-darker;
    content: '» ';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -1px;
  }

  * + * {
    margin-top: 12px;
  }
}
.seperator {
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid $ibe-color-pink;
}

.trainConnection {
  padding-right: 2px;
  padding-left: 2px;
  h5 {
    color: $ibe-color-pink;
  }
}

.connections {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.connection {
  flex: 1;

  display: flex;
  flex-direction: column;

  border: 1px solid $gray-300;
  border-top-width: 3px;
  border-top-color: $primary;
}

.connectionHeader {
  padding: 0.571rem 1.143rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  // white-space: nowrap;
  flex: 0 0;
}

.connectionMenue {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $gray-300;
  flex: 1;

  > ul {
    flex: 1;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  > div {
    flex: 1;
    margin: 0;
    padding: 1em;
  }
}
.connectionButton {
  color: $primary;
  width: 100%;
  background-color: $white;
  border: 0;
  margin: 0;
  padding: 0.2857rem 1.143rem;
  cursor: pointer;
  display: block;
  text-align: center;
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  margin-top: -1px;
  margin-bottom: -1px;

  &:hover {
    background-color: $gray-100;
  }

  &:disabled {
    opacity: 0.4;
    cursor: initial;
  }
}
.connectionInformationContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
}
.connectionMoreInformationContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.connectionMoreInformationContainerRow {
  display: flex;
  flex-direction: row;
  > div {
    display: flex;
    flex-direction: row;
    flex: 1 1;
  }
}

.connectionMoreInformationDetailContainerRow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: $gray-600;
  @media only screen and (min-width: $screen-md-min) {
    flex-direction: row;
  }

  > div {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-right: 1rem;
  }
}
.connectionMoreInformationDetailContainerRow + .connectionMoreInformationDetailContainerRow {
  border-top: 1px solid $gray-300;
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  @media only screen and (min-width: $screen-md-min) {
    padding-top: 0;
    margin-top: 0;
    border: 0;
  }
}

.connectionItemContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border-bottom: 1px solid $gray-300;
  border-top: 1px solid $gray-300;
  width: 100%;
  background-color: $white;
  cursor: pointer;
  margin-top: -1px;

  @media only screen and (min-width: $screen-lg-min) {
    flex-direction: row;
  }
}

.connectionItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: nowrap;
  padding: 0.571rem 0.643rem 0.571rem 1.143rem;
  border-right: 1px solid $gray-300;
}

.connectionItemInput {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.571rem 1.143rem 0.571rem 1.143rem;
  min-width: 260px;
  @include checkboxAndRadio();

  input[type='radio'] + label:before {
    top: 50%;
  }
  input[type='radio'] + label:after {
    top: 50%;
  }
  input[type='radio'] + label {
    margin-bottom: 0;
  }

  @media only screen and (min-width: $screen-md-min) {
    padding: 0.571rem 1.143rem 0.571rem 2rem;
  }

  @media only screen and (min-width: $screen-lg-min) {
    justify-content: initial;
  }
}

.connectionItemInputPrice {
  font-size: 36px;
  font-weight: 600;
}

.connectionItemActive {
  box-shadow: inset 0 2px 3px 0 rgba($primary, 0.6);
  background-color: rgba($primary, 0.1);
}

.connectionInformation {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  > div {
    display: flex;
    flex-direction: row;
  }
}

.connectionItemPrice {
  align-self: center;
  text-align: center;
  font-weight: 600;
  margin: auto;
  flex: 1 1;
}

.connectionDetailInformation {
  color: $gray-600;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.arrow {
  font-size: 1.5em;
  padding: 0 10px;
  line-height: 0.9em;
}

.time {
  font-size: 18px;
  font-weight: bolder;
}

.duration {
  font-size: 12px;
  font-weight: lighter;
}

.connectionDetail {
  display: flex;
  flex-direction: row;
  margin-top: 0.5rem;
  flex-wrap: wrap;
}

.connectionDetailItem {
  flex: 0 1 auto;
  padding: 0.3rem 0.65rem;
  background-color: $gray-900;
  text-align: center;
  margin: 0.5rem 0.5rem 0.5rem 0;
  font-size: 12px;
  color: $white;
  font-weight: 600;
  border-radius: 4px;
}

.connectionItemConnection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
  font-size: 14px;
}

.trainInputRadioLabel {
  padding-top: 12px;
  display: flex;
  cursor: pointer;
}
.trainInputRadioLabelMargin {
  margin-top: 50%;
  padding-top: 12px;
  display: flex;
  cursor: pointer;
}
.trainInputRadio {
  @include checkboxAndRadio();
}

@media only screen and (min-width: $screen-md-min) {
  .noPadding {
    padding-left: 0;
  }
}

.trainBundleContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2rem 0;

  @media only screen and (min-width: $screen-lg-min) {
    justify-content: space-around;
  }
}

.trainBundleCard {
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  width: 100%;
  margin-bottom: 1rem;

  &__direction {
    font-size: 12px;
    font-weight: lighter;
  }

  &__name {
    font-weight: bolder;
  }

  &__conditions {
    margin-top: 1rem;
  }

  @media only screen and (min-width: $screen-lg-min) {
    max-width: 45%;
  }
}
