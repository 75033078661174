@import '../../../../Assets/Styles/Pepxpress/Variables/Colors';
@import '../../../../Assets/Styles/Pepxpress/Mixins/MediaQuery';

.row {
  margin-top: 12px;
}

.row__withChildren {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;

  > div:not(:last-child) {
    margin-right: 12px;
  }
}

.row__withBorder {
  border-top: 1px solid $ibe-color-gray-300;
  margin-top: 12px;
  padding-top: 12px;
}

.validationMessage {
  margin: 12px 0;
}

.label {
  display: block;
}

.dateTime {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.direction,
.date {
  padding-right: 6px;
}

.timestamp,
.time {
  padding-left: 6px;
}

.errors {
  .invalid-feedback {
    display: block;
  }
}

.iframe {
  height: 712px;
  width: 900px;
  border-width: 0;
  position: relative;
  left: -8px;

  &__container {
    height: 500px;
    overflow: auto;
    margin-top: 10px;
  }
}

.button {
  width: 100%;

  svg {
    font-size: 14px;
    position: relative;
    top: -1px;
    margin-left: 15px;
  }

  &Small {
    max-width: 250px;
  }

  &BottomContainer {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;

    > button:first-child {
      margin-bottom: 10px;
    }

    @include small {
      flex-direction: row;
      align-items: initial;

      > button:first-child {
        margin-bottom: 0;
      }
    }
  }
}

.formGroup {
  flex: 1 1 auto;
}
