@import '../../../../Assets/Styles/Pepxpress/Variables/Colors';
@import '../../../../Assets/Styles/Pepxpress/Mixins/MediaQuery';
@import '../../../../Assets/Styles/Pepxpress/Mixins/Radio';

.optionInput {
  width: 100%;
  $paddingLeft: 12px;
  @include checkboxAndRadio($paddingLeft);
}

.form {
  .textfield {
    max-width: 20rem;
    margin-bottom: 5px;
  }
  label {
    margin-bottom: 0;
    input[type="checkbox"] {
      align-self: flex-start;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      -webkit-accent-color: #a94442;
      accent-color: #a94442;
      margin-top: 5px;
      margin-right: 5px;
    }
  }
  .errortext {
    color: #a94442;
    font-size: 12px;
    line-height: 1.5;
  }
}
