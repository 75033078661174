@import '../../Assets/Styles/Pepxcite/Variables/Colors';

.item,
.fakeFlightItem {
  background-color: $primary;
  color: $ibe-color-white;
  padding: 12px;
  padding-bottom: 6px;
}

.fakeFlightItem + .item {
  padding-top: 0px;
}

.dateAndPrice,
.room,
.earlyBird {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 6px;
  border-top: 1px solid $primary-light;
  padding-top: 6px;
}

.date {
  flex-shrink: 1;
}

.price {
  width: 96px;
  flex-shrink: 0;
  text-align: right;
}
.flihgtItem {
  background-color: $white;
  padding: 12px;
  padding-bottom: 6px;
  border-left: 1px solid $primary;
  border-right: 1px solid $primary;
}
.flightDescription {
  padding-top: 6px;
  // margin-top     : 6px;
  border-top: 1px solid $primary-light;
}

.flihgtDateAndPrice {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 6px;
  border-top: 1px solid $ibe-color-gray-300;
  padding-top: 6px;
}

.infoIcon {
  color: $primary;
  position: relative;
  right: 3px;
  top: -2px;
}
