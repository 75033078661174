@import '../../../Assets/Styles/Pepxpress/Variables/Colors';

.h1 {
  color: $ibe-color-pink;
}

.h2,
.p {
  color: $ibe-color-gray-500;
}
