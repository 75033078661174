@import '../../../Assets/Styles/Pepxcite/Variables/Colors';

.container {
  display: inline-flex;
}

.title {
}

.containerImageToggle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.toggleContainer {
  margin-left: 0.5rem;
}

.icon {
  margin-left: 6px;

  img {
    display: block;
    width: auto; /* img tag might override this */
    height: auto; /* img tag might override this */
    max-width: 120px;
    max-height: 24px;
  }
}

.optionInfoLink {
  span {
    color: $primary-light;
    font-size: 11px;
    text-align: right;
    display: block;
    line-height: 12px;
    margin-right: 6px;
  }

  svg {
    color: $primary;
  }

  &:hover {
    text-decoration: none;

    span {
      color: $primary-dark;
    }

    svg {
      fill: $primary-dark;
    }
  }
}
