@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';

.container {
}

.section + .section {
  margin-top: 24px;
}

.optionInput {
  width: 100%;
  $paddingLeft: 12px;
  @include checkboxAndRadio($paddingLeft);
}

.optionInput + .optionInput {
  margin-top: 12px;
}

.optionLabel {
  padding: 12px;
  display: flex;
  margin: 0;
  padding: 12px;
  cursor: pointer;
}

.optionBorder {
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $ibe-color-pink;
  }
}

.note {
  position: relative;
  padding-left: 20px;
  margin-top: 12px;

  svg {
    font-size: 24px;
    position: absolute;
    left: -6px;
    top: -1px;
    fill: $ibe-color-gray-400;
  }
}

.info {
  color: $ibe-color-pink !important;
}

.paymentRedirect {
  color: $ibe-color-gray-600;
  margin-top: 12px;
  padding-left: 40%;
  font-size: 14px;
  text-align: right;
}
