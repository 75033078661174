@import '~Assets/Styles/Pepxpress/Variables/Colors';

.container {
  h3 {
    margin: 0 0 24px 0;
  }

  h5,
  h4 {
    margin: 24px 0 12px 0;
  }
}

.passengers {
  margin-top: 12px;
}

.passenger + .passenger {
  margin-top: 12px;
}

.mandatoryNote {
  margin: 24px 0;
}

.apiError {
  margin: 24px 0;
}
