@import '~Assets/Styles/Pepxcite/Variables/Colors';

@mixin checkboxAndRadioSwitches($paddingLeft: 0px) {
  $height: 20px;

  input {
    display: none;
  }

  label {
    cursor: pointer;
    padding-left: $paddingLeft + ($height * 2.5);
    position: relative;
    display: block;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    visibility: hidden !important;
  }

  input[type='checkbox'] + label,
  input[type='radio'] + label {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: $height/-2;
      box-sizing: border-box;
      transition: all 350ms ease-out;
    }

    &:before {
      width: $height * 2;
      height: $height;
      left: $paddingLeft;
      background: $ibe-color-gray-200;
      border: 1px solid $ibe-color-gray-200;
      border-radius: $height;
    }

    &:after {
      width: $height;
      height: $height;
      left: $paddingLeft;
      background: $ibe-color-gray-100;
      border: 1px solid $ibe-color-gray-200;
      border-radius: 50%;
    }
  }

  input[type='checkbox']:checked + label,
  input[type='radio']:checked + label {
    &:before {
      background: lighten($primary, 5%);
      border-color: $primary;
    }

    &:after {
      left: $paddingLeft + $height;
      border-color: $primary;
    }
  }
}

@mixin checkboxAndRadio($paddingLeft: 0px) {
  $height: 20px;

  & > input {
    display: none;
  }

  & > label {
    cursor: pointer;
    padding-left: $paddingLeft + ($height * 1.5);
    position: relative;
    display: block;
  }

  & > input[type='checkbox'],
  & > input[type='radio'] {
    position: absolute;
    visibility: hidden !important;
  }

  & > input[type='checkbox'] + label,
  & > input[type='radio'] + label {
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 24px; // 50%;
      margin-top: $height/-2;
      box-sizing: border-box;
      transition: all 350ms ease-out;
    }

    &:before {
      width: $height;
      height: $height;
      left: $paddingLeft;
      background: $ibe-color-gray-200;
    }

    &:after {
      width: $height;
      height: $height;
      left: $paddingLeft;
      background: $ibe-color-gray-200;
    }
  }

  & > input[type='radio'] + label {
    &:before,
    &:after {
      border-radius: 100%;
    }

    &:before {
    }

    &:after {
      width: 6px;
      height: 6px;
      left: $paddingLeft + ($height / 2) - 3px;
      margin-top: -3px;
    }

    &:hover {
      &:after {
        background-color: $ibe-color-gray-400;
      }
    }
  }

  input[type='checkbox'] + label {
    &:before,
    &:after {
    }

    &:before {
      border-radius: 2px;
    }

    &:after {
      left: $paddingLeft + ($height / 2) -3px;
      margin-top: -8px;
      width: 7px;
      height: 12px;
      border: solid $ibe-color-gray-200;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      background-color: transparent;
    }

    &:hover {
      &:after {
        border-color: $ibe-color-gray-400;
      }
    }
  }

  input[type='checkbox']:checked + label {
    &:before {
      background: $primary;
    }

    &:after {
      border-color: $ibe-color-white;
    }
  }

  input[type='radio']:checked + label {
    &:before {
      background-color: $primary;
    }

    &:after {
      background-color: $ibe-color-white;
    }
  }
}
