@import '../../Assets/Styles/Pepxcite/Mixins/MediaQuery';

.layout {
  display: block;
}

.top {
  margin-bottom: 24px;
}

.leftRight {
}

.left {
}

.right {
  margin-top: 24px;
}

.bottom {
  margin-top: 24px;
}

.layout--2col {
  .leftRight {
    @include medium() {
      display: flex;
      flex-wrap: nowrap;
    }
  }
}
