@import '~Assets/Styles/Pepxpress/Variables/Colors';
@import '~Assets/Styles/Pepxpress/Mixins/Radio';
@import '~Assets/Styles/Pepxpress/Variables/Breakpoints';

.section + .section {
  margin-top: 24px;
}

.optionInput {
  width: 100%;
  $paddingLeft: 12px;
  @include checkboxAndRadio($paddingLeft);
}

.option {
  position: relative;
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  svg {
    fill: $ibe-color-gray-400;
  }
}

.optionInfo {
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;

  &:hover {
    svg {
      fill: $ibe-color-pink;
    }
  }
}

.optionInfoLink {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  margin-left: 12px;

  span {
    color: #c9608d;
    font-size: 11px;
    text-align: right;
    display: block;
    line-height: 12px;
    margin-right: 6px;
  }

  svg {
    color: $ibe-color-pink;
  }

  &:hover {
    text-decoration: none;

    span {
      color: #630030;
    }

    svg {
      fill: #630030;
    }
  }
}

.optionDescription {
  padding: 12px;
  padding-bottom: 0;
}

.optionDescriptionTop {
  padding-right: 36px;
}

.optionDescriptionBottom {
  padding-bottom: 12px;

  * {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 18px;
  }

  ul {
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7 {
    font-weight: bold;
  }

  li {
    position: relative;
    padding-left: 16px;
  }

  li:before {
    color: $ibe-color-purple-darker;
    content: '» ';
    font-size: 18px;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: -1px;
  }

  * + * {
    margin-top: 6px;
  }
}

.descriptionBonuscard {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img {
    max-width: 30%;
    padding-left: 12px;

    img {
      display: block;
      max-width: 90%;
      height: auto;
      transform: rotate(-5deg);
      margin-top: 5%;
    }
  }

  .text {
    flex-grow: 1;
  }
}

.descriptionFox {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img {
    max-width: 30%;
    padding-left: 12px;

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  .text {
    flex-grow: 1;
  }
}

.optionInfoContainer {
  display: flex;
  justify-content: space-between;
}

.optionInput + .optionInput {
  margin-top: 12px;
}

.optionLabel {
  padding: 12px;
  display: flex;
  margin: 0;
  padding: 12px;

  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: baseline;
    margin: 0;
    cursor: pointer;
  }

  &:before, &:after {
    top: 50% !important;
  }
}

.donationInput {
  & > select {
    @media only screen and (min-width: $screen-lg-min) {
      width: 20%;
    }
  }

  & > label {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.optionLabelWithCollapse {
  display: flex;
  margin: 0;
  padding: 12px;

  & > div {
    display: flex;
    margin: 0;
    flex-direction: column;

    & > div {
      display: flex;
      justify-content: space-between;
      vertical-align: baseline;
    }
  }
}

.optionBorder {
  border: 1px solid $ibe-color-gray-200;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    border-color: $ibe-color-gray-400;
  }

  &--isSelected,
  &--isSelected:hover {
    border-color: $ibe-color-pink;
  }
}

.coupon {
  display: flex;

  button {
    margin-left: 12px;
    flex-shrink: 0;
  }
}

.info {
  background-color: $ibe-color-gray-200;
  border-radius: 4px;
  padding: 12px 18px;

  .line {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--bold {
      font-weight: bold;
    }
  }

  .line + .line,
  .text + .text {
    margin-top: 3px;
  }

  .texts {
    margin-top: 12px;
  }
}

.codeApplied {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
