@import '../../../../Assets/Styles/Pepxpress/Variables/Colors';
@import '../../../../Assets/Styles/Pepxpress/Mixins/MediaQuery';

.form {
  select:disabled {
    -moz-appearance: window;
    -webkit-appearance: none;
  }
}

.row + .row {
  margin-top: 12px;
}

.fieldGroup {
  display: flex;
  flex-direction: column;
  margin: -6px 0;
  &:global(.noWrap) {
    flex-flow: nowrap;
  }
  @include medium() {
    flex-direction: row;
    margin: -6px;
  }
  & > * {
    margin: 6px 0;
    @include medium() {
      margin: 6px;
    }
  }
}

.fieldGroup100 {
  > div {
    width: 100%;
  }
  display: flex;
  margin: -6px;
  & > * {
    margin: 6px;
  }
}

.fieldGroupWrap {
  display: flex;
  margin: -6px;
  flex-wrap: wrap;
  & > * {
    margin: 6px;
  }

  @include medium() {
    flex-wrap: nowrap;
  }
}

.salutation {
  flex: 0 1 auto;
}

.salutationContainer {
  width: 100%;
  @include medium() {
    width: 16%;
  }
  min-width: 90px;
}

.firstname,
.surname {
  width: 100%;
  @include medium() {
    width: 42%;
  }
  min-width: 0; // FF -_-
}

.street {
  width: 84%;
  padding-right: 10px;
  @include medium() {
    padding-right: initial;
  }
}

.number {
  width: 16%;
  min-width: 60px;
  /* @include medium() {
    width: 16%;
  } */
}

.postcode {
  width: 100%;
  min-width: 70px;
  @include medium() {
    width: 20%;
  }
}

.city {
  width: 100%;
  @include medium() {
    width: 80%;
  }
}

.note {
  font-size: 12px;
  color: $ibe-color-gray-600;
}

.passportNumber {
  width: 100%;

  @include medium() {
    width: 70%;
  }
}

.passportValidThru {
  width: 100%;

  @include medium() {
    width: 30%;
  }
}
