.break {
    word-break : break-word;
}

.preloader {
    display          : flex;
    position         : fixed;
    width            : 100%;
    height           : 100%;
    left             : 0;
    top              : 0;
    right            : 0;
    bottom           : 0;
    background-color : white;
    opacity          : .5;
    transition       : opacity .75s linear;
    /* uncomment to center
    align-items      : center;
    justify-content  : center;
    */
}


.preloader.off {
    opacity : 0;
}

.preloader .spinner {
    width    : 40px;
    height   : 40px;
    position : relative;
    margin   : 100px auto;
}

.preloader .spinnerBounce1,
.preloader .spinnerBounce2 {
    width             : 100%;
    height            : 100%;
    border-radius     : 50%;
    background-color  : gray;
    opacity           : 0.6;
    position          : absolute;
    top               : 0;
    left              : 0;
    -webkit-animation : preloaderBounce 2.0s infinite ease-in-out;
    animation         : preloaderBounce 2.0s infinite ease-in-out;
}

.preloader .spinnerBounce2 {
    -webkit-animation-delay : -1.0s;
    animation-delay         : -1.0s;
}

@-webkit-keyframes preloaderBounce {
    0%,
    100% {
        -webkit-transform : scale(0.0)
    }
    50% {
        -webkit-transform : scale(1.0)
    }
}

@keyframes preloaderBounce {
    0%,
    100% {
        transform         : scale(0.0);
        -webkit-transform : scale(0.0);
    }
    50% {
        transform         : scale(1.0);
        -webkit-transform : scale(1.0);
    }
}

.img {
    display   : block;
    max-width : 100%;
    height    : auto;
}
