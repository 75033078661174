@import '../../../../Assets/Styles/Pepxcite/Variables/Colors';

.row {
  margin-top: 12px;
}

.row__withChildren {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;

  > * + * {
    margin-left: 12px;
  }
}

.row__withBorder {
  border-top: 1px solid $ibe-color-gray-300;
  margin-top: 12px;
  padding-top: 12px;
}

.validationMessage {
  margin: 12px 0;
}

.label {
  display: block;
}

.dateTime {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.direction,
.date {
  padding-right: 6px;
}

.timestamp,
.time {
  padding-left: 6px;
}

.errors {
  .invalid-feedback {
    display: block;
  }
}
