@import '../../Assets/Styles/Pepxcite/Variables/Colors';
@import '../../Assets/Styles/Pepxcite/Variables/Breakpoints';
@import '../../Assets/Styles/Pepxcite/Mixins/MediaQuery';

$phi: 1.6180339887498948482;

.cart {
  display: flex;
  flex-direction: column;

  .image {
    & > div {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.items {
  border: 1px solid $primary;
  padding: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.image {
  & > div {
    border-radius: 4px;
    overflow: hidden;
  }
}

.allItems {
  overflow: hidden;
}

.included {
  margin-top: 12px;
}

.cart--expanded {
  @include small {
    flex-direction: row-reverse;
    justify-content: stretch;

    .image {
      width: 100% - 100% / $phi;
      padding-left: 24px;
      & > div {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .allItems {
      flex-grow: 1;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

.cart--narrow {
  display: flex;
}

.hideInSmall {
  display: none;
  @include small {
    display: block;
  }
}

.showInSmall {
  display: block;
  @include small {
    display: none;
  }
}

.incl {
  margin-bottom: 6px;
  padding: 0;
}

.status {
  border-radius: 4px;
  border: 1px solid transparent;
  font-weight: 700;
  padding: 6px;
  font-size: 15px;
  margin-top: 12px;
  display: flex;
  align-items: center;

  svg {
    font-size: 26px;
    margin-right: 5px;
  }

  &--request {
    background-color: $sand;
    border-color: darken($sand-dark, 15%);

    svg {
      color: darken($sand-dark, 15%);
    }
  }

  &--binding {
    background-color: $green-light;
    border-color: darken($green-dark, 15%);

    svg {
      color: darken($green-dark, 15%);
    }
  }
}
